// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD5TtITYGrXxlWNRbbWN3UYwTep8jZfIwA",
  authDomain: "hollrs-app-test.firebaseapp.com",
  projectId: "hollrs-app-test",
  storageBucket: "app-store.hollrs.com",
  messagingSenderId: "320198042375",
  appId: "1:320198042375:web:d429042b7d4bdc18995ba2",
  measurementId: "G-R6WJBFZ3XY"
};

export const APP_VERSION = 'test';
export const REGION = 'europe-west2';

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);
export const functions = getFunctions(app, REGION);
export const storage = getStorage(app, firebaseConfig.storageBucket);
