import React, { useContext } from 'react';

import PageContainer from '../../../components/container/PageContainer';
import Map from '../../dashboard/components/Map';
import { AuthContext } from '../../../contexts/AuthProvider';
import { adminDashboardMap } from '../../../firebase/functions';

const AdminMapPage = () => {
  const { currentUser } = useContext(AuthContext);

  return (
    <PageContainer>
      <Map user={currentUser} height={document.body.clientHeight - 272} dataFunction={adminDashboardMap} />
    </PageContainer>
  );
}

export default AdminMapPage;
