import React, { useContext } from 'react';

import { adminListFunction } from '../../firebase/functions';
import { InternalQRPage } from './internal';
import { AuthContext } from '../../contexts/AuthProvider';

const QRPage = () => {
  const { currentUser } = useContext(AuthContext);

  const [qrCodes, setQrCodes] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rowLength, setRowLength] = React.useState(0);

  const [loading, setLoading] = React.useState(false);

  const associateId = currentUser.associateId;

  React.useEffect(() => {
    setLoading(true);
    adminListFunction('listQrCodes', page, rowsPerPage, { associateId }).then(({ list, totalSize }) => {
      setQrCodes(list || []);
      setRowLength(totalSize || 0);
      setLoading(false);
    }).catch((err) => {
      console.error(err);
      setLoading(false);
    });
  }, [page, rowsPerPage]);

  return InternalQRPage({
    associateId,
    qrCodes,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    rowLength,
    setQrCodes,
    loading,
  });
};

export default QRPage;
