import React from 'react';
import QRCodeEditPage from './edit';
import PageContainer from '../../components/container/PageContainer';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { adminUpdateFunction } from '../../firebase/functions';
import { TablePaginationActions } from '../../components/table/pagination';

export const InternalQRPage = (
  {
    associateId,
    qrCodes,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    rowLength,
    setQrCodes,
    loading,
  }) => {

  const [selectedQrCode, setSelectedQrCode] = React.useState(null);
  const [creating, setCreating] = React.useState(false);

  const onClosEdit = ({ id, editState, action }) => {
    // console.log({ id, editState, action });
    if (action === 'submit') {
      // process all key values from editState
      setQrCodes((prev) => {
        const newRows = prev.map((row) => {
          if (row.id === id) {
            return {
              ...row,
              ...editState,
            };
          }
          return row;
        });
        return newRows;
      });
    }

    setSelectedQrCode(null);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowLength) : 0;

  if (selectedQrCode) {
    return (
      <QRCodeEditPage qrCode={selectedQrCode} associateId={associateId} onClose={onClosEdit} />
    );
  }

  // if (newAssociate) {
  //   return (
  //     <AdminNewAssociate onClose={(newAssociateHandler) => setNewAssociate(false)} />
  //   )
  // }

  return (
    <PageContainer title='Admin QR codes' description='Admin QR codes page'>
      <Card>
        <CardContent>
          <Box
            sx={{
              overflow: {
                xs: 'auto',
                md: 'unset',
              },
            }}
          >
            <Box display='flex' justifyContent='flex-end'>
              <Button
                variant='contained'
                color='primary'
                onClick={async (e) => {
                  e.preventDefault();
                  setCreating(true);
                  adminUpdateFunction('createQrCode', '', {
                    associateId,
                    tags: [],
                    hiddenTags: [],
                  }).then((res) => {
                    if (res.data?.success === true) {
                      const data = res.data?.data || {};
                      setQrCodes((prev) => {
                        return [
                          ...prev,
                          data,
                        ];
                      });
                      setSelectedQrCode(data);
                    }
                    setCreating(false);
                  }).catch((err) => {
                    alert(err);
                    console.error(err);
                    setCreating(false);
                  });
                }}
                disabled={creating}
              >
                {creating && <CircularProgress size={24} />}
                + New QR code
              </Button>
            </Box>
            <Table
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h5'>Short code</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h5'>Tags</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h5'>Subtitle</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              {loading
                ?
                <CircularProgress />
                :
                <TableBody>
                  {qrCodes.map((qrCode) => (
                    <TableRow
                      key={qrCode.id}
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedQrCode(qrCode);
                      }}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <TableCell>
                        <Box display='flex' alignItems='center'>
                          <Box
                            sx={{
                              ml: 2,
                            }}
                          >
                            <Typography variant='h6' fontWeight='600'>
                              {qrCode.code}
                            </Typography>
                            <Typography variant='caption'>
                              {qrCode.id}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h6'>
                          {qrCode.tags?.length > 0 &&
                            qrCode.tags.map((tag) => {
                              return (
                                <Chip
                                  key={tag}
                                  label={tag}
                                  color={'primary'}
                                />
                              );
                            })
                          }
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h6'>
                          {qrCode.subTitle}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              }
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={6}
                    count={rowLength}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputprops: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={(e, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(event) => {
                      setRowsPerPage(parseInt(event.target.value, 10));
                      setPage(0);
                    }}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        </CardContent>
      </Card>
    </PageContainer>
  );
};
