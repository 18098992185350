import React, { useContext } from 'react';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Snackbar,
  Typography,
} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import CustomTextField from '../../components/forms/custom-elements/CustomTextField';
import CustomFormLabel from '../../components/forms/custom-elements/CustomFormLabel';
import PageContainer from '../../components/container/PageContainer';
import { uploadFile } from '../../components/forms/files';
import { callFunction } from '../../firebase/functions';
import { AuthContext } from '../../contexts/AuthProvider';
import { useNavigate } from 'react-router-dom';

const AdminCompanyEditPage = ({ company, onClose }) => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const [editCompany, setEditCompany] = React.useState({});

  const [saving, setSaving] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [alertMessage, setAlertMessage] = React.useState({
    open: false,
    type: 'error',
    message: '',
  });

  const associateId = currentUser.associateId;

  const updateCompany = React.useCallback((editCompany) => {
    setSaving(true);

    const data = {
      companyID: associateId,
      firstName: editCompany.firstName,
      email: editCompany.email,
      username: editCompany.username,
      profilePictureURL: editCompany.profilePictureURL,
    };

    callFunction('associate', 'updateCompany', data).then((res) => {
      if (res?.data?.success === true) {
        setAlertMessage({open: true, type: 'success', message: 'Company updated successfully'});
      } else {
        console.error('Failed to update company', res);
        setAlertMessage({open: true, type: 'error', message: 'Failed to update company: ' + res?.data?.message});
      }
      setSaving(false);
    }).catch((err) => {
      console.error(err);
      setAlertMessage({open: true, type: 'error', message: 'Failed to update company'});
      setSaving(false);
    });
  }, [associateId]);

  React.useEffect(() => {
    setLoading(true);
    callFunction('associate', 'getCompany', { companyID: associateId }).then((res) => {
      if (!res.data || !res.data.company) {
        console.error('No company data found');
      } else {
        setEditCompany(res.data.company);
      }
      setLoading(false);
    }).catch((err) => {
      console.error(err);
      setLoading(false);
    });
  }, [associateId]);

  if (loading) {
    return (
      <PageContainer>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='100%'
        >
          <CircularProgress />
        </Box>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      {alertMessage.open && (
        <Snackbar
          open={alertMessage.open}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={6000}
          onClose={() => setAlertMessage({...alertMessage, open: false})}
        >
          <Alert
            severity={alertMessage.type}
            variant='filled'
            sx={{ width: '100%' }}
            onClose={() => setAlertMessage({...alertMessage, open: false})}
          >
            {alertMessage.message}
          </Alert>
        </Snackbar>
      )}
      <Card
        sx={{
          p: 0,
        }}
      >
        <Box
          sx={{
            padding: '15px 30px',
          }}
          display='flex'
          alignItems='center'
        >
          <Box display='flex' flexGrow={1} flexDirection='row' alignItems='center'>
            <Avatar
              src={editCompany.profilePictureURL}
              alt={editCompany.profilePictureURL}
              width='30'
              sx={{
                borderRadius: '100%',
                marginRight: '16px',
              }}
            />
            <Typography fontWeight='500' variant='h4'>
              {editCompany.firstName || ''}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          display='flex'
          alignItems='center'
          p={2}
          sx={{
            backgroundColor: 'primary.light',
            color: 'primary.main',
          }}
        >
          <FeatherIcon icon='alert-circle' width='18' />
          <Box sx={{ ml: 1 }}>Company Info</Box>
        </Box>
        <CardContent
          sx={{
            padding: '0 30px',
            marginBottom: '20px',
          }}
        >
          <form>
            <Grid container spacing={1}>
              <Grid item lg={6} md={6} sm={12}>
                <CustomFormLabel htmlFor='fname-text'>Company Name</CustomFormLabel>
                <CustomTextField
                  id='fname-text'
                  fullWidth
                  size='small'
                  value={editCompany.firstName}
                  onChange={(e) => setEditCompany((state) => {
                    return { ...state, firstName: e.target.value };
                  })}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <CustomFormLabel htmlFor='fname-text'>APP user name</CustomFormLabel>
                <CustomTextField
                  id='fname-text'
                  fullWidth
                  size='small'
                  value={editCompany.username}
                  onChange={(e) => setEditCompany((state) => {
                    return { ...state, username: e.target.value };
                  })}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <CustomFormLabel htmlFor='fname-text'>Logo</CustomFormLabel>
                <CustomTextField
                  id='fname-text'
                  variant='outlined'
                  fullWidth
                  size='small'
                  value={editCompany.profilePictureURL}
                  onChange={(e) => setEditCompany((state) => {
                    return { ...state, profilePictureURL: e.target.value };
                  })}
                />
                <input
                  type='file'
                  accept='image/*'
                  onChange={(e) => {
                    uploadFile(e, () => {
                    }, (downloadURL) => {
                      setEditCompany(state => ({ ...state, profilePictureURL: downloadURL }));
                    });
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <CustomFormLabel htmlFor='lname-text'>Email</CustomFormLabel>
                <CustomTextField
                  id='lname-text'
                  variant='outlined'
                  fullWidth
                  size='small'
                  value={editCompany.email}
                  onChange={(e) => setEditCompany((state) => {
                    return { ...state, email: e.target.value };
                  })}
                />
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <Box pt={3}>
          <Divider />
          <Box p={3} display='flex' justifyContent='flex-end'>
            <Button
              variant='contained'
              color='error'
              sx={{
                mr: 1,
              }}
              onClick={(e) => {
                e.preventDefault();
                navigate('/');
              }}
              disabled={saving}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={async (e) => {
                e.preventDefault();
                updateCompany(editCompany);
              }}
              disabled={saving}
            >
              {saving && <CircularProgress size={'16px'} style={{ marginRight: '8px' }} />}
              Save
            </Button>
          </Box>
        </Box>
      </Card>
    </PageContainer>
  );
};

export default AdminCompanyEditPage;
