import React from 'react';
import { Avatar, Box, Button, Card, CardContent, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import CustomTextField from '../../../components/forms/custom-elements/CustomTextField';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import PageContainer from '../../../components/container/PageContainer';
import { uploadFile } from '../../../components/forms/files';
import { adminUpdateFunction } from '../../../firebase/functions';

const AssociateCompanyPage = ({ company, onClose }) => {
  const [editUser, setEditUser] = React.useState({});
  const [editCompany, setEditCompany] = React.useState({});

  const [saving, setSaving] = React.useState(false);

  return (
    <PageContainer>
      <Card
        sx={{
          p: 0,
        }}
      >
        <Box
          sx={{
            padding: '15px 30px',
          }}
          display='flex'
          alignItems='center'
        >
          <Box display='flex' flexGrow={1} flexDirection='row' alignItems='center'>
            <Avatar
              src={editUser.profilePictureURL || company.user.profilePictureURL}
              alt={editUser.profilePictureURL || company.user.profilePictureURL}
              width='30'
              sx={{
                borderRadius: '100%',
                marginRight: '16px',
              }}
            />
            <Typography fontWeight='500' variant='h4'>
              {editUser.firstName || company.user.firstName || ''} <small> - {company.user.id}</small>
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          display='flex'
          alignItems='center'
          p={2}
          sx={{
            backgroundColor: 'primary.light',
            color: 'primary.main',
          }}
        >
          <FeatherIcon icon='alert-circle' width='18' />
          <Box sx={{ ml: 1 }}>Company Info</Box>
        </Box>
        <CardContent
          sx={{
            padding: '0 30px',
            marginBottom: '20px',
          }}
        >
          <form>
            <Grid container spacing={1}>
              <Grid item lg={6} md={6} sm={12}>
                <CustomFormLabel htmlFor='fname-text'>Company Name</CustomFormLabel>
                <CustomTextField
                  id='fname-text'
                  fullWidth
                  size='small'
                  value={editUser.firstName || company.user.firstName}
                  onChange={(e) => setEditUser((state) => {
                    return { ...state, firstName: e.target.value };
                  })}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <CustomFormLabel htmlFor='fname-text'>APP user name</CustomFormLabel>
                <CustomTextField
                  id='fname-text'
                  fullWidth
                  size='small'
                  value={editUser.username || company.user.username}
                  onChange={(e) => setEditUser((state) => {
                    return { ...state, username: e.target.value };
                  })}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <CustomFormLabel htmlFor='fname-text'>Logo</CustomFormLabel>
                <CustomTextField
                  id='fname-text'
                  variant='outlined'
                  fullWidth
                  size='small'
                  value={'' || editUser.profilePictureURL || company.user.profilePictureURL}
                  onChange={(e) => setEditUser((state) => {
                    return { ...state, profilePictureURL: e.target.value };
                  })}
                />
                <input
                  type='file'
                  onChange={(e) => {
                    uploadFile(e, () => {
                    }, (downloadURL) => {
                      setEditUser(state => ({ ...state, profilePictureURL: downloadURL }));
                    });
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <CustomFormLabel htmlFor='lname-text'>Email</CustomFormLabel>
                <CustomTextField
                  id='lname-text'
                  variant='outlined'
                  fullWidth
                  size='small'
                  value={editUser.email || company.user.email}
                  onChange={(e) => setEditUser((state) => {
                    return { ...state, email: e.target.value };
                  })}
                />
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <Divider />
        <Box
          display='flex'
          alignItems='center'
          p={2}
          sx={{
            backgroundColor: 'primary.light',
            color: 'primary.main',
          }}
        >
          <FeatherIcon icon='alert-circle' width='18' />
          <Box sx={{ ml: 1 }}>Company Huddl</Box>
        </Box>
        <CardContent
          sx={{
            padding: '0 30px',
          }}
        >
          <Grid container spacing={1}>
            {company.user.huddlID
              ?
              <Grid item lg={6} md={6} sm={12}>
                <CustomFormLabel htmlFor='fname-text'>Huddl ID</CustomFormLabel>
                <CustomTextField
                  id='fname-text'
                  fullWidth
                  size='small'
                  value={company.user.huddlID}
                  disabled
                />
              </Grid>
              :
              <Grid item lg={6} md={6} sm={12} mt={2}>
                <Button
                  variant='contained'
                  color='error'
                  sx={{
                    mr: 1,
                  }}
                  onClick={async (e) => {
                    e.preventDefault();
                    setSaving(true);
                    try {
                      // update user info in db
                      const response = await adminUpdateFunction('createCompanyHuddl', '', {id: company.user.id, data: {}});
                      // success: false, message: 'Username already taken'
                      if (response?.data?.success === true) {
                        onClose({ id: company.user.id, action: 'update', editUser: { huddlID: response.data.huddlID }, editCompany: {} });
                      } else {
                        alert(response?.data?.message || "An error occurred");
                      }
                    } catch (e) {
                      alert(e);
                      console.log(e);
                    }
                    setSaving(false);
                  }}
                  disabled={saving}
                >
                  Create company Huddl
                </Button>
              </Grid>
            }
          </Grid>
        </CardContent>
        <Box pt={3}>
          <Divider />
          <Box p={3} display='flex' justifyContent='flex-end'>
            <Button
              variant='contained'
              color='error'
              sx={{
                mr: 1,
              }}
              onClick={(e) => {
                e.preventDefault();
                onClose({ id: company.user.id, action: 'cancel' });
                setEditUser({});
              }}
              disabled={saving}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={async (e) => {
                e.preventDefault();
                setSaving(true);
                try {
                  if (onClose({ id: company.user.id, editUser, editCompany, action: 'submit' })) {
                    setEditUser({});
                  } else {
                    return;
                  }
                } catch (e) {
                  alert(e);
                  console.log(e);
                }
                setSaving(false);
              }}
              disabled={saving}
            >
              {saving && <CircularProgress size={'16px'} style={{ marginRight: '8px' }} />}
              Save
            </Button>
          </Box>
        </Box>
      </Card>
    </PageContainer>
  );
};

export default AssociateCompanyPage;
