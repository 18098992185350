import React, { useEffect } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';

import DashboardCard from '../../../components/base-card/DashboardCard';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import moment from 'moment';
import Spinner from '../../spinner/Spinner';
import { callFunction } from '../../../firebase/functions';

mapboxgl.accessToken = 'pk.eyJ1Ijoic2lnZ2lob2xscnMiLCJhIjoiY2xvYm1iYzAyMHc1ZzJqcXFza2tjejY2eSJ9._kCnc3QHucUKSEgTqtIVbQ';

const MapContainer = styled(Box)(({ theme, height }) => ({
  height: height || '400px',
}));

const Map = ({ user, height, dataFunction }) => {
  const mapContainer = React.useRef(null);
  const map = React.useRef(null);
  const [lng, setLng] = React.useState(-0.4312128);
  const [lat, setLat] = React.useState(51.5281798);
  const [zoom, setZoom] = React.useState(6);

  const [markersLoaded, setMarkersLoaded] = React.useState(false);
  const [markers, setMarkers] = React.useState([]);

  React.useEffect(() => {
    // get the current location of the user
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLng(position.coords.longitude);
        setLat(position.coords.latitude);
      });
    }
  }, []);

  if (!dataFunction) {
    dataFunction = async (userID, associateID) => {
      const res = await callFunction('associate', 'dashboardMap', {
        userID,
        associateID,
      }).catch(e => { console.error(e); });

      return res?.data;
    };
  }

  React.useEffect(() => {
    const fetchData = async () => {
      const data = await dataFunction(user.uid, user.associateId);

      if (data?.markers) {
        setMarkers(data.markers);
      }

      setMarkersLoaded(true);
    };

    fetchData().catch(e => console.error(e));
  }, []);

  useEffect(() => {
    if (map.current || !markersLoaded) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [lng, lat],
      zoom: zoom,
    });
    map.current.on('load', () => {
      // Add a new source from our GeoJSON data and
      // set the 'cluster' option to true. GL-JS will
      // add the point_count property to your source data.
      map.current.addSource('hollrs', {
        type: 'geojson',
        //data: 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
        data: markers,
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
      });

      map.current.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'hollrs',
        filter: ['has', 'point_count'],
        paint: {
          // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#69b10a',
            100,
            '#f1f075',
            750,
            '#f28cb1'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }
      });

      map.current.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'hollrs',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': ['get', 'point_count_abbreviated'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }
      });

      map.current.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'hollrs',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#69b10a',
          'circle-radius': 12,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });

      // inspect a cluster on click
      map.current.on('click', 'clusters', (e) => {
        const features = map.current.queryRenderedFeatures(e.point, {
          layers: ['clusters']
        });
        const clusterId = features[0].properties.cluster_id;
        map.current.getSource('hollrs').getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;

            map.current.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            });
          }
        );
      });

      // When a click event occurs on a feature in
      // the unclustered-point layer, open a popup at
      // the location of the feature, with
      // description HTML from its properties.
      map.current.on('click', 'unclustered-point', (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const data = e.features[0].properties;
        const tags = data.tags ? JSON.parse(data.tags) : [];

        //console.log({ data, tags })

        // Ensure that if the map is zoomed out such that
        // multiple copies of the feature are visible, the
        // popup appears over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(`
            ${data.username || data.firstName || ''} @${moment(new Date(data.createdAt * 1000)).format('YYYY-MM-DD HH:mm')}<br/>
            ${data.name ? data.name + '<br>' : ''}
            ${data.text}<br/>
            ${data.commentCount || 0} comments<br/>
            ${data.reactionsCount || 0} reactions<br/>
            Sentiment: ${data.sentiment || 'neutral'}<br/>
            ${tags.length > 0 ? '#' + tags.join(' #') : ''}<br/>
            <div style="display: flex; justify-content: flex-end;">
              <button onclick="window.location.href='/hollr/${data.id}'">View</button>
            </div>
          `)
          .addTo(map.current);
      });

      map.current.on('mouseenter', 'clusters', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseleave', 'clusters', () => {
        map.current.getCanvas().style.cursor = '';
      });
    });

  }, [map, markersLoaded]);

  return (
    <DashboardCard title="Map of Hollrs" subtitle="Map of Hollrs">
      {!markersLoaded && <Spinner />}
      <MapContainer ref={mapContainer} height={height} />
    </DashboardCard>
  );
}

export default Map;
